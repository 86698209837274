import React from 'react';
import { Layout, RichTextHTML, Section } from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessProvider';
import { CartContext } from '../contexts/CartProvider';

const LocationsPage = () => {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);
  const cart = React.useContext(CartContext);

  const faqs = yellowBusiness?.website?.faqs;

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          isEcommerce
          yellowBusiness={yellowBusiness}
          sections={sections}
          cart={cart}
          pageName={sections?.faqSection?.name}
        >
          {faqs && (
            <Section section={{ name: 'Frequently asked questions' }}>
              <div className="space-y-4">
                {faqs.map((entry, ix) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={ix} className="space-y-1">
                    <div className="font-bold">{entry.question}</div>
                    <RichTextHTML>{entry.answer}</RichTextHTML>
                  </div>
                ))}
              </div>
            </Section>
          )}
        </Layout>
      )}
    </>
  );
};

export default LocationsPage;
